<template>
  <SalesProperties />
</template>

<script>
import SalesProperties from '@/components/MessageComponents/SalesProperties.vue';

export default {
  name: 'sales-valuations-view',
  components: {
    SalesProperties
  }
  ,
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>