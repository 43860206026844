 <template>
  <div>
    <v-container>
      <!-- Sales valuations aka Sales properties in $store -->
      <v-card flat tile>
        <div class="card-title-wrapper" id="sticky-nav">
          <v-card-title class="py-2 grey lighten-4 d-flex">
            <div class="text-center" @click="toDashboard">
              <v-icon>mdi-arrow-left</v-icon>
            </div>
            <div class="mx-auto">
              <span class="headline font-weight-light">{{dataType}}</span>
              <p class="text-center mb-0 mt-n1 caption">
                <span v-if="returnDataByType.length > 1">{{ returnDataByType.length }} valuations</span>
                <span v-else-if="returnDataByType.length == 1">{{ returnDataByType.length }} valuation</span>
                <span v-else>no valuations</span>
              </p>
            </div>

          </v-card-title>
          <div class="white py-3 d-flex justify-center">
            <v-btn 
              @click="setDataType('Branch sales valuations')" 
              :outlined="dataType !== 'Branch sales valuations'"
              :class="{'white--text': dataType === 'Branch sales valuations'}"
              class="mr-1 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('Branch completed')" 
              :outlined="dataType !== 'Branch completed'"
              :class="{'white--text': dataType === 'Branch completed'}"
              class="mr-5 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('Staff sales valuations')" 
              :outlined="dataType !== 'Staff sales valuations'" 
              :class="{'white--text': dataType === 'Staff sales valuations'}"
              class="mr-1 text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-account</v-icon>

            </v-btn>
            <v-btn 
              @click="setDataType('My completed')" 
              :outlined="dataType !== 'My completed'" 
              :class="{'white--text': dataType === 'My completed'}"
              class="text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
          </div>
        </div>

        <v-card-text v-if="returnDataByType.length" class="px-0 pt-0 mb-0">
          <v-card class="px-1" flat outlined tile v-for="item in returnDataByType" :key="item.OID">
          <v-card-text>
              <div>
                <span v-if="item.PrimaryVendor.Surname">Owner</span>
                <span v-else>Owner (not specified)</span>
                <span v-if="item.PrimaryVendor.Surname">
                  <span v-if="item.PrimaryVendor.Title">PrimaryVendor{{item.PrimaryVendor.Title}}</span>{{item.PrimaryVendor.Forename}} {{item.PrimaryVendor.Surname}}
                </span> 
                has submitted an online valuation 
                <span v-if="item.BedroomCount">for a <strong>{{item.BedroomCount}} bedroom</strong></span>
                <strong v-if="item.Type"> {{item.Type}}</strong> 
                <span v-if="item.Address"> at <strong>{{item.Address}}</strong></span> 
                <span v-if="item.Price"> at a vendor valuation of <strong >{{item.Price | filterPrice}}. </strong></span>
                <br>Please access agentOS to import valuation.
              </div>
              <div class="mt-5 text-right text-md-left">
                <div class="font-weight-light">Owner details:</div>
                <div v-if="item.PrimaryVendor.EmailAddress" class="primary--text">{{item.PrimaryVendor.EmailAddress}}</div>
                <div v-if="item.PrimaryVendor.MobilePhone" class="primary--text">{{item.PrimaryVendor.MobilePhone}}</div>
                <div class="font-weight-light" v-if="!item.PrimaryVendor.EmailAddress && !item.PrimaryVendor.MobilePhone">n/a</div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text class="mt-5" v-else>
          <NoDataMsg>
            Sales valuations
          </NoDataMsg>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NoDataMsg from '@/components/MessageComponents/Slots/NoDataMsg.vue';

export default {
  components: {
    NoDataMsg
  },
  name: 'sales-properties',
  data() {
    return {
      dataType: 'Branch sales valuations'
    }
  },
  methods: {
    toDashboard() {
      this.$router.go(-1)
    },
    setDataType(type) {
      this.dataType = type
    }
  },
  computed: {
    ...mapState({
      branch: state => state.salesProperties.branch.data,
      branchCompleted: state => state.salesProperties.branchCompleted.data,
      staff: state => state.salesProperties.staff.data,
      staffCompleted: state => state.salesProperties.staffCompleted.data
    }),
    returnDataByType() {
      if(this.dataType === 'Staff sales valuations') {return this.staff.Data}
      if(this.dataType === 'My completed') {return this.staffCompleted.Data}
      if(this.dataType === 'Branch completed') {return this.branchCompleted.Data}
      return this.branch.Data
    }
  },
  filters: {
    filterPrice(val) {
      return val.toLocaleString('en-UK', {style: 'currency', currency: 'GBP'}) 
    }
  } 
}
</script>

<style>

</style>